import React, { useContext } from 'react';
//style
import './WizardContainerSelector.scss';
//navigation
import {
	Route,
	Switch,
	NavLink,
	useRouteMatch,
	Redirect
} from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
//context
import { CreateContainerProvider } from '../../components/Context/createContainerContext/Context';
//helpers
import { PrivateRoute } from '../../lib/Helper/RoutesHelper';
//pages
import CreateContainer from './CreateContainer';
import ContainerList from './ContainerList';
//lib
import ArrowLeft from '../../svg/ArrowLeft';
import { SensorContextType } from '../../components/Context/ContextType';
import {useSensorContext} from '../../components/Context/Context';
import Header from '../../components/Header/Header';
import {OriginCreateContainerContext} from "../../components/Context/OriginCreateContainerContext";
import {isContainerReadOnlyOrganization} from "../../domain/organization-rule";



export const useOriginCreateContainerContext = () => {
	const context = useContext(OriginCreateContainerContext);
	if (!context) {
		throw new Error(
			'The provider for the OriginCreateContainerContext is not defined'
		);
	}
	return context;
};

//

export default function WizardContainerSelector() {
	let { path, url } = useRouteMatch();
	const sensorContext: SensorContextType = useSensorContext();
	const location = useLocation();
	const fromWizard = !location.pathname.startsWith('/containers-list');
	const organizationId = fromWizard
		? sensorContext.sensor.organizationObject.id
		: sensorContext.getOrganization().replace('/v2/organizations/', '');
	const urlPrefix = fromWizard
		? '/wizard/container-selector/'
		: '/containers-list/';

	const history = useHistory();
	// @ts-ignore
	return (
		<OriginCreateContainerContext.Provider
			value={{ fromWizard, organizationId, urlPrefix }}
		>
			<div id="ContainerSelector">
				{fromWizard && (
					<header id="HeaderTitleWithBackButton">
						<button onClick={() => history.goBack()} aria-label="Précédent">
							<ArrowLeft />
						</button>
						<h2>Sélectionner un contenant</h2>
					</header>
				)}

				{!fromWizard && <Header></Header>}
				<div>
					{ !isContainerReadOnlyOrganization(sensorContext.getUserOrganization()) && <nav id="container-selector__menu">
						<ul>
							<li className="ContainerSelectorMenu__navlink">
								<NavLink
									activeClassName="activeLink"
									to={`${url}/list`}
									replace={true}
								>
									{' '}
									Liste{' '}
								</NavLink>
							</li>
							<li className="ContainerSelectorMenu__navlink">
								<NavLink
									activeClassName="activeLink"
									to={`${url}/create`}
									replace={true}
								>
									{' '}
									Création{' '}
								</NavLink>
							</li>
						</ul>
					</nav>}
					<div>
						<Switch>
							<Route exact path={`${path}`}>
								<Redirect to={`${path}/list`} />
							</Route>
							<PrivateRoute path={`${path}/list`} Component={ContainerList} />
							<CreateContainerProvider>
								<PrivateRoute
									path={`${path}/create`}
									Component={CreateContainer}
								/>
							</CreateContainerProvider>
						</Switch>
					</div>
				</div>
			</div>
		</OriginCreateContainerContext.Provider>
	);
}
