import React, { ReactElement } from 'react';
import { classWithModifiers } from '../utils/css';
import Icon from '../../components/Icons/Icon';
import { Loader } from '../../svg/loader';

type ButtonProps = {
	label: string | ReactElement;
	type: 'button' | 'submit' | 'reset' | undefined;
	onClick: any;
	classModifiers?: string[];
	iconLeft?: ReactElement;
	iconRight?: ReactElement;
	disabled?: boolean;
	loader?: boolean;
	ariaLabel?: string;
};
export const Button = ({
	label,
	type,
	onClick,
	classModifiers,
	iconLeft,
	iconRight,
	disabled,
	loader = false,
	ariaLabel
}: ButtonProps) => {
	return (
		// Button
		<button
			disabled={disabled}
			type={type}
			onClick={onClick}
			aria-label={ariaLabel}
			className={classWithModifiers(`btn`, [
				...(classModifiers || []),
				{ 'with-icon': iconLeft || iconRight },
				{ 'with-left-icon': iconLeft || loader },
				{ 'with-right-icon': iconRight }
			])}
		>
			{loader && <Loader />}
			{iconLeft && <Icon className="btn__left-icon" iconPath={iconLeft} />}
			<span className="btn__label">{label}</span>
			{iconRight && <Icon className="btn__right-icon" iconPath={iconRight} />}
		</button>
		// ---
	);
};
