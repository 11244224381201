import React, {KeyboardEvent} from "react";
import {InputText} from "../../../../lib/Forms/InputText/InputText";

type FilterProps = {
    onKeyUp: (search: string) => void
}
export const Filter = ({ onKeyUp }: FilterProps) => {
    return (
        <div className="container-model-filter">
            <InputText
                placeholder="Nom du PAV"
                type="text"
                classModifiers={[]}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyUp((e.target as HTMLInputElement).value.toLowerCase())}
            />
        </div>
    )
}
