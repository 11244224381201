import React, { useContext, useEffect, useState } from 'react';
//styles
import './ListGroup.scss';
import { HeaderTitleWithBackButton } from '../../../components/Header/Header';
//navigation
import { useHistory } from 'react-router';
//context
import {
	useCreateContainerContext
} from '../../../components/Context/createContainerContext/Context';
import {
	Group,
	CreateContainerContextType
} from '../../../components/Context/createContainerContext/ContextType';
//libs
import Loading from '../../../components/FabButton/Loading/Loading';
import {hasDepositPoints} from "../../../api/depositPoints";
import {fetchAllFlows} from "../../../api/flows";
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from "../../../components/Context/OriginCreateContainerContext";
import {Flow} from "../../../components/Context/ContextType";
import {FOUND, NOTFOUND} from "../../../api/model";


const mapping = {
	BIO_WASTE: 'Bio-déchet',
	BOTTLING: 'Flaconnage',
	CARDBOARD: 'Carton',
	CIGARETTE: 'Cigarette',
	COMMON_BASKET: 'Poubelle',
	COMMON: 'Ordure ménagère',
	COMPOST: 'Compost',
	GLASS: 'Verre',
	GREEN: 'Déchet Vert',
	LIQUID: 'Liquide',
	OTHER: 'Autre',
	PAPER: 'Papier/Carton',
	PAPER_ONLY: 'Papier',
	RECYCLABLE: 'Emballage',
	TEXTILE: 'Textile'
};

type ListFlowTypes = {
	status: typeof FOUND | typeof NOTFOUND,
	flows: Flow[]
}
export const ListFlow = ({ status, flows }: ListFlowTypes) => {

	const { organizationId, urlPrefix } = useContext(
		OriginCreateContainerContext
	) as OriginCreateContainerContextType;

	const createContainerContext: CreateContainerContextType = useCreateContainerContext();

	const history = useHistory();
	const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

	const goNext = async () => {
		const hasDP = await hasDepositPoints(organizationId);
		if(hasDP){
			history.push(urlPrefix + 'create/deposit');
			return;
		}
		history.push(urlPrefix + 'create/summary');
		return;
	};

	return (
		<div className="createContainerPage">
			<HeaderTitleWithBackButton title={'Flux'} />
			<div
				id="ContainerSelector"
				className={selectedGroup ? 'disabled' : ''}
				onClick={() => selectedGroup && setSelectedGroup(null)}
			>
				<div className="container-list-group">
					{status === NOTFOUND && <Loading />}
					{status === FOUND && flows.length > 0 && (
						<ul id="containerList">
							{flows
								.map((flow) => ({
									...flow,
									label: mapping[flow.key] ?? flow.key
								}))
								.sort((flow1, flow2) => flow1.label.localeCompare(flow2.label))
								.map((flow) => {
									return (
										<li
											key={flow.key}
											role="button"
											tabIndex={0}
											onClick={() => {
												setSelectedGroup(flow);
												createContainerContext.overrideContainer({
													flow: flow['@id'],
													flowLabel: flow.label
												});
												goNext();
											}}
										>
											<span className="cardTitle">{flow.label}</span>
										</li>
									);
								})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
}
export default function ListFlowContainer() {
	const [status, setStatus] = useState(NOTFOUND);
	const [flows, setFlows] = useState<any[]>([]);

	const { organizationId } = useContext(
		OriginCreateContainerContext
	) as OriginCreateContainerContextType;

	useEffect(() => {
		fetchAllFlows(organizationId).then(flows => {
			setStatus(FOUND);
			setFlows(flows)
		});
	}, [organizationId]);

	return <ListFlow status={status} flows={flows}></ListFlow>
}
