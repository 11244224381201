import { InputText } from '../../../lib/Forms/InputText/InputText';
import {
	ChangeEvent,
	useContext,
	useEffect,
	useState
} from 'react';
import { Radio } from '../../../lib/Forms/Radio/Radio';
import { Checkbox } from '../../../lib/Forms/Checkbox/Checkbox';
import WizardFooter from '../../../components/Footer/WizardFooter';
import {
	Container,
	DepositPoint
} from '../../../components/Context/ContextType';
import { Option, Select } from '../../../components/select';
import { fetchAllDepositPoints } from '../../../api/depositPoints';
import {statusOptions, tagsOptions} from "../../../api/model";
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from "../../../components/Context/OriginCreateContainerContext";

type ContainerUpdateFormProps = {
	container: Container;
	onCancel: () => void;
	onUpdate: (
		editableName: string | null,
		editableTags: string[],
		editableStatus: string | null | undefined,
		editableDepositPoint: string | null | undefined
	) => void;
};
export const ContainerUpdateForm = ({
	container,
	onCancel,
	onUpdate
}: ContainerUpdateFormProps) => {
	const { organizationId } = useContext(
		OriginCreateContainerContext
	) as OriginCreateContainerContextType;

	const [depositPoints, setDepositPoints] = useState<DepositPoint[]>([]);

	const [editableName, setEditableName] = useState<string | null>(
		container.name
	);
	const [depositPointId, setDepositPointId] = useState<string | null>(
		container.depositPoint?.['@id']
	);
	const [editableTags, setEditableTags] = useState<string[]>(
		container.tags ?? []
	);
	const [editableStatus, setEditableStatus] = useState<
		string | undefined | null
	>(container?.logisticStatus);

	useEffect(() => {
		fetchAllDepositPoints(organizationId).then(points => setDepositPoints(points));
	}, [organizationId]);

	const pavOptions = depositPoints.map((depositPoint) => ({
		value: depositPoint['@id'],
		label: depositPoint.name
	}));
	return (
		<div id="ContainerSelectorRename" className="page">
			<div className="rename__content">
				<h2>Modifier un contenant</h2>
				<InputText
					label="Nom"
					value={editableName}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						setEditableName(e.target.value)
					}
				/>
				<Select
					options={pavOptions}
					placeholder={<>PAV</>}
					value={pavOptions.find((option) => option.value === depositPointId)}
					onChange={({ value }: Option) => setDepositPointId(value)}
				></Select>
				<fieldset
					onChange={(e: Event) => {
						const radioEvent = e as ChangeEvent<HTMLInputElement>;
						setEditableStatus(radioEvent.target.value);
					}}
				>
					<legend>Status</legend>
					{
						statusOptions.map((status) => (
							<Radio name="status" label={status.label} value={status.value} checked={editableStatus === status.value} />
						))
					}
				</fieldset>

				<fieldset
					onChange={(e: Event) => {
						const checkboxEvent = e as ChangeEvent<HTMLInputElement>;
						if (!checkboxEvent || !checkboxEvent.target) {
							return null;
						}
						if (editableTags?.includes(checkboxEvent.target.value!)) {
							setEditableTags(
								editableTags?.filter(
									(tag) => tag !== checkboxEvent.target.value
								)
							);
						} else {
							setEditableTags([...editableTags, checkboxEvent.target.value]);
						}
					}}
				>
					<legend>Tag</legend>
					{
						tagsOptions.map((tag) => (
							<Checkbox label={tag.label} value={tag.value} checked={editableTags?.includes(tag.value)} />
						))
					}
				</fieldset>
			</div>

			<WizardFooter
				next={() => {
					onUpdate(
						editableName,
						editableTags,
						editableStatus,
						depositPoints.find((d) => d['@id'] === depositPointId)?.['@id']
					);
				}}
				previous={() => {
					setEditableName(null);
					setEditableTags([]);
					setEditableStatus(null);
					setDepositPointId(null);
					onCancel();
				}}
				previousLabel="Annuler"
				nextLabel="Confirmer"
			/>
		</div>
	);
};
