import React from 'react';
import { DepositPoint } from '../../../../components/Context/ContextType';

type DepositPointListTypes = {
	list: DepositPoint[];
	onSelect: (depositPoint: DepositPoint) => void;
};
export const DepositPointList = ({
	list,
	onSelect
}: Readonly<DepositPointListTypes>) => {
	return (
		<ul id="containerList">
			{list
				.sort((d1, d2) => d1.name.localeCompare(d2.name))
				.map((d) => {
					return (
						<li
							key={d.id}
							role="button"
							tabIndex={0}
							onClick={() => onSelect(d)}
						>
							<span className="cardTitle">{d.name}</span>
						</li>
					);
				})}
		</ul>
	);
};
