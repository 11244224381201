import './ContainerList.scss';
//state
import {useHistory} from 'react-router';
import {
    ChangeEvent,
    MutableRefObject,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import pThrottle from 'p-throttle';
import {
    Container,
    SensorContextType
} from '../../components/Context/ContextType';
//context
import SensorContext from '../../components/Context/Context';
//lib
import {InputText} from '../../lib/Forms/InputText/InputText';
//components
import Loading from '../../components/FabButton/Loading/Loading';
import {StatusSelect, TagsSelect} from '../../components/select';
//api
import {
    getContainerList,
    updateContainerName
} from '../../api';
import {
    GeolocationPositionOrigin,
    GeolocationPositionWithOrigin,
    getCurrentPosition
} from '../../api/geolocation';
import {createLogger} from '../../monitoring/logrocket';
import {AskForStatus} from './ContainerList/AskForStatus';
import {AskForSav} from './ContainerList/AskForSav';
import {ContainerActiveContractsWarning} from './ContainerList/ContainerActiveContractsWarning';
import {ContainerListItem} from './ContainerList/ContainerListItem';
import {ContainerUpdateForm} from './ContainerList/ContainerUpdateForm';

import {ContainerDrawer} from "./ContainerList/ContainerDrawer";
import {useAsyncError} from "../../components/ErrorBoundaries/ErrorBoundaries";
import {getFlowsConfiguration} from "../../api/flows";
import {
    OriginCreateContainerContext,
    OriginCreateContainerContextType
} from "../../components/Context/OriginCreateContainerContext";
import {FOUND, NOTFOUND} from "../../api/model";

const logger = createLogger('Container List Page');

const throttle = pThrottle({
    limit: 2,
    interval: 300
});

export default function ContainerList() {
    const throwError = useAsyncError();
    const [statusFilter, setStatusFilter] = useState<string>('TO_EQUIP');
    const [tagsFilter, setTagsFilter] = useState<string[]>([]);
    const previousController: MutableRefObject<AbortController | undefined> =
        useRef();
    const history = useHistory();
    const {organizationId, fromWizard} = useContext(
        OriginCreateContainerContext
    ) as OriginCreateContainerContextType;
    const [selectedContainer, setSelectedContainer] = useState<Container | null>(
        null
    );
    const [position, setPosition] = useState<GeolocationPositionWithOrigin | null>(null);
    const sensorContext: SensorContextType = useContext(SensorContext);
    const [containerList, setContainerList] = useState<any[] | undefined>();
    const [flows, setFlows] = useState<any[] | undefined>();
    const [activeContract, setActiveContract] = useState<boolean>(false);
    const [askForSAV, setAskForSAV] = useState<boolean>(false);
    const [askForTagStatus, setAskForTagStatus] = useState<boolean>(false);
    const [filter, setFilter] = useState();
    const [editable, setEditable] = useState<Boolean>(false);

    const onKeyDown = (e) => {
        const value = e.target.value;
        logger.log('Filtering', value);
        setFilter(value);
    };

    const displayWarningMessage = (origin: GeolocationPositionOrigin | undefined) => {
        if (origin === 'localstorage') {
            throwError(new Error("geoloc_not_uptodate"))
        } else if (origin === 'unknown') {
            throwError(new Error("geoloc_unknown"))
        }
    }

    const getContainerListData = throttle(
        (filter: string, status: string, tags: string[]) => {
            setContainerList(undefined);
            logger.log('Fetch container List');

            if (previousController.current) {
                previousController!.current.abort();
            }

            const controller = new AbortController();
            const signal = controller.signal;
            previousController.current = controller;

            displayWarningMessage(position?.origin);

            getContainerList(
                signal,
                organizationId,
                position?.coords,
                filter,
                status,
                tags
            ).then((data) => {
                setContainerList(data);
            });
        }
    );

    const status = containerList === undefined ? NOTFOUND : FOUND;

    useEffect(() => {
        getFlowsConfiguration().then(setFlows);
    }, []);

    useEffect(() => {
        logger.log('Fetching Current Position');
        getCurrentPosition().then((position: GeolocationPositionWithOrigin) => {
            logger.log('Receiving Current Position', JSON.stringify(position));
            setPosition(position);
        });
    }, []);

    useEffect(
        () => {
            if (position === null) {
                logger.log("Coords are not yet defined, we won't fetch the container list");
                return;
            }

            logger.log('Fetching the container list');
            getContainerListData(filter, statusFilter, tagsFilter);
        }, // eslint-disable-next-line
        [position, filter, statusFilter, tagsFilter]
    );

    const replaceOldContract = (
        action: string,
        koSensors?: string[],
        tag?: string
    ) => {
        if (selectedContainer) {
            //prettier-ignore
            logger.log('Replace an old Contract', action, koSensors?.join(',') ?? '', tag ?? '');
            sensorContext.setSensor({
                ...sensorContext.sensor,
                removeContainerContracts: action === 'replace',
                tagKoSensors: koSensors,
                tagValueKoSensors: tag,
                container: selectedContainer
            });
            history.push('/wizard/container-validation');
        }
    };

    if (editable && !!containerList) {
        return (
            <ContainerUpdateForm
                container={selectedContainer!}
                onCancel={() => setEditable(false)}
                onUpdate={(
                    editableName,
                    editableTags,
                    editableStatus,
                    editableDepositPointIri
                ) => {
                    updateContainerName(
                        selectedContainer,
                        editableName!,
                        editableStatus!,
                        editableTags,
                        editableDepositPointIri
                    ).then((newContainer) => {
                        setContainerList(
                            containerList.map((container) => {
                                if (container.id === selectedContainer?.id) {
                                    return {
                                        ...container,
                                        ...{
                                            name: newContainer.name,
                                            logisticStatus: newContainer.logisticStatus,
                                            depositPoint: newContainer.depositPoint
                                        }
                                    };
                                }
                                return container;
                            })
                        );
                        setSelectedContainer({
                            ...selectedContainer,
                            ...newContainer
                        } as Container);
                        setEditable(false);
                    });
                }}
            ></ContainerUpdateForm>
        );
    }

    return (
        <>
            {askForTagStatus && selectedContainer && (
                <AskForStatus
                    container={selectedContainer}
                    onChoiceSelect={({action, serialNumbers, tag}) => {
                        replaceOldContract(action, serialNumbers, tag);
                        setAskForTagStatus(false);
                    }}
                />
            )}
            {askForSAV && selectedContainer && (
                <AskForSav
                    container={selectedContainer}
                    onYes={() => {
                        setAskForSAV(false);
                        setAskForTagStatus(true);
                    }}
                    onNo={() => {
                        replaceOldContract('replace');
                        setAskForSAV(false);
                    }}
                />
            )}
            {activeContract &&
                selectedContainer &&
                !askForSAV &&
                !askForTagStatus && (
                    <ContainerActiveContractsWarning
                        container={selectedContainer}
                        currentSerialNumber={sensorContext.sensor.serialNumber}
                        onCancel={() => {
                            setActiveContract(false);
                            setSelectedContainer(null);
                        }}
                        onAdd={() => replaceOldContract('add')}
                        onReplace={() => setAskForSAV(true)}
                    ></ContainerActiveContractsWarning>
                )}

            <div
                id="ContainerSelector"
                className={selectedContainer ? 'disabled' : ''}
                onClick={() => selectedContainer && setSelectedContainer(null)}
            >
                <div className="drawerShadow"/>

                <div className="containerList">
                    <div className="container-model-filter">
                        <InputText
                            placeholder="Nom du container"
                            type="text"
                            classModifiers={[]}
                            onKeyUp={(e: ChangeEvent<HTMLInputElement>) => onKeyDown(e)}
                        />
                        <div id="filters">
                            <StatusSelect value={statusFilter} onChange={setStatusFilter}/>
                            <TagsSelect onChange={setTagsFilter}/>
                        </div>
                    </div>

                    {status === NOTFOUND && <Loading/>}
                    {status === FOUND && (
                        <ul id="containerList">
                            {containerList.map((container) => {
                                return (
                                    <li
                                        key={container.id}
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => {
                                            setSelectedContainer(container);
                                        }}
                                    >
                                        <ContainerListItem container={container} flows={flows}/>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>

            {selectedContainer && (
                <ContainerDrawer container={selectedContainer} fromWizard={fromWizard} onEdit={() => setEditable(true)}
                                 onConfirm={() => {
                                     if (selectedContainer.activeContracts.length > 0) {
                                         setActiveContract(true);
                                     } else {
                                         sensorContext.setSensor({
                                             ...sensorContext.sensor,
                                             container: selectedContainer
                                         });
                                         history.push('/wizard/container-validation');
                                     }
                                 }}></ContainerDrawer>
            )}
        </>
    );
}
