import React, { useContext, useEffect, useState } from 'react';
//styles
import './ListGroup.scss';
import { HeaderTitleWithBackButton } from '../../../components/Header/Header';
//navigation
import { useHistory } from 'react-router';
//context
import CreateContainerContext from '../../../components/Context/createContainerContext/Context';
import { getGroupList } from '../../../api';
import {
	Group,
	CreateContainerContextType
} from '../../../components/Context/createContainerContext/ContextType';
//libs
import Loading from '../../../components/FabButton/Loading/Loading';
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from "../../../components/Context/OriginCreateContainerContext";
import {FOUND, NOTFOUND} from "../../../api/model";


export default function ListGroup() {
	const history = useHistory();
	const { organizationId, urlPrefix } = useContext(OriginCreateContainerContext) as OriginCreateContainerContextType

	const createContainerContext: CreateContainerContextType = useContext(
		CreateContainerContext
	);
	const [status, setStatus] = useState(NOTFOUND);
	const [groupList, setGroupList] = useState<any[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

	const goNext = () => {
		history.push(urlPrefix + 'create/container-models');
	};

	const getGroupListData = (nextUrl?: string) => {
		return getGroupList(
			organizationId,
			nextUrl
		).then(({ groupList: data, next }) => {
			if(data){
				setStatus(FOUND);
				setGroupList([...groupList, ...data])
				next && getGroupListData(next);
			}
		});
	};

	useEffect(
		() => {
			getGroupListData()
		}, // eslint-disable-next-line
		[]
	);

	return (
		<div className="createContainerPage">
			<HeaderTitleWithBackButton title={'Groupes'} />
			<div
				id="ContainerSelector"
				className={selectedGroup ? 'disabled' : ''}
				onClick={() => selectedGroup && setSelectedGroup(null)}
			>
				<div className="container-list-group">
					{status === NOTFOUND && <Loading />}
					{status === FOUND && groupList.length > 0 && (
						<ul id="containerList">
							{groupList.map((group) => {
								return (
									<li
										key={group.id}
										role="button"
										tabIndex={0}
										onClick={() => {
											setSelectedGroup(group);
											createContainerContext.overrideContainer({
												group: {
													name: group.name,
													iri: group['@id'],
													id: group.id
												}
											});
											goNext();
										}}
									>
										<span className="cardTitle">{group.name}</span>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
}
