import { Button } from '../../lib/Buttons/Button';

type NextButtonProps = {
	disabled?: boolean;
	next: any;
	label?: string;
	loader?: boolean;
	type?: 'button' | 'submit' | 'reset' | undefined;
};
export default function NextButton({
	disabled,
	next,
	label = 'Suivant',
	loader,
	type = 'button'
}: NextButtonProps) {
	return (
		<Button
			type={type}
			disabled={disabled}
			label={label}
			onClick={next}
			loader={loader}
		/>
	);
}
