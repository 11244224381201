import {createContext} from "react";

export type OriginCreateContainerContextType = {
    fromWizard: boolean;
    organizationId: string;
    urlPrefix: string;
};
export const OriginCreateContainerContext = createContext<
    OriginCreateContainerContextType | undefined
>(undefined);
