import React, { ChangeEvent, useContext, useState } from 'react';
//styles
import './ContainerName.scss';
import NextButton from '../../../components/NextButton/NextButton';
//navigation
import { useHistory } from 'react-router';
//libs
import { Cover } from '../../../components/Animation/Animation';
import { InputText } from '../../../lib/Forms/InputText/InputText';
//context
import { CreateContainerContextType } from '../../../components/Context/createContainerContext/ContextType';
import CreateContainerContext from '../../../components/Context/createContainerContext/Context';
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from "../../../components/Context/OriginCreateContainerContext";

export default function ContainerName() {
	const history = useHistory();
	const { urlPrefix } = useContext(OriginCreateContainerContext) as OriginCreateContainerContextType

	const containerContext: CreateContainerContextType = useContext(
		CreateContainerContext
	);
	const [editableName, setEditableName] = useState<string | undefined>();

	const goNext = () => {
		containerContext.overrideContainer({ name: editableName });
		history.push(urlPrefix + 'create/groups');
	};

	return (
		<div id="containerSelectorName" className="createContainerPage">
			<Cover path="/images/big/container_edit" />
			<div id="containerSelectorNameForm">
				<h2>Nom du nouveau contenant</h2>
				<form name="container_name">
					<InputText
						placeholder="exemple: container"
						value={editableName}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setEditableName(e.target.value)
						}
					/>
					<NextButton
						type="button"
						disabled={editableName === undefined}
						next={goNext}
					/>
				</form>
			</div>
		</div>
	);
}
