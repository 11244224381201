import React, {ComponentProps } from 'react';
import { classWithModifiers } from '../../utils/css';

type InputTextProps = {
	label?: string;
	message?: string;
	classModifiers?: string[];
} & ComponentProps<"input">

export const InputText = ({
	label,
	message,
	classModifiers,
	...props
}: InputTextProps) => {
	return (
		// InputText
		<label
			className={classWithModifiers('hl-input-text', [
				...(classModifiers || [])
			])}
		>
			<div className="hl-input-text__label">{label}</div>
			<input
				{...props}
				autoComplete="off"
				className="hl-input-text__input"
			/>
			{message && <div className="hl-input-text__message">{message}</div>}
		</label> // ---
	);
};
