
export type ServerSideApiPayload<T> ={
    'hydra:member': T[],
    'hydra:view': {
        'hydra:next': string | null
    },
    'hydra:totalItems': number
}

export type ClientSideApiPayload<T> ={
    data?: T[],
    next?: string | null,
    totalItem?: number
}

export const PENDING = 'PENDING';
export const FOUND = 'FOUND';
export const NOTFOUND = 'NOT_FOUND';


type FetchArgumentsWithoutName = [string, RequestInit];
type FetchArgumentsWithName = [string, RequestInit, string];

export type FetchArguments = FetchArgumentsWithName | FetchArgumentsWithoutName

export type Coordinate = {
    latitude: number;
    longitude: number
};

type NetWorkType = {
    LORA: string;
    SIGFOX: string;
}
export const Network: NetWorkType = {
    LORA: 'LoRa',
    SIGFOX: 'Sigfox'
};
export type NetWorkKey = keyof NetWorkType;


type ProviderType = {
    SIGFOX: string,
    LIVE_OBJECT: string,
    OBJENIOUS: string,
    PROXIMUS: string,
    HEYWANESYTESTER: string,
    SWISS_COM: string,
    MILE_SIGHT: string,
    LORIOT:string,
    HELIUM: string
}
export const Provider: ProviderType = {
    SIGFOX: 'Sigfox',
    LIVE_OBJECT: 'Live Object',
    OBJENIOUS: 'Objenious',
    PROXIMUS: 'Proximus',
    HEYWANESYTESTER: 'HeyWanesyTester',
    SWISS_COM: 'SwissCom',
    MILE_SIGHT: 'MileSight',
    LORIOT: 'Loriot',
    HELIUM: 'Helium'
} as const;

export type ProviderKey = keyof ProviderType;

export type Option = { value: string; label: string, style?: `${string}-status` };
export type Options = Option[];

export const statusOptions: Options = [
    { value: 'EQUIPPED', label: 'Equipé', style: 'active-status' },
    { value: 'TO_EQUIP', label: 'A équiper', style: 'default-status' },
    { value: 'NOT_EQUIPPABLE', label: 'Non équipable', style: 'warning-status' },
    { value: 'NOT_TO_EQUIP', label: 'A ne pas équiper', style: 'inactive-status' }
];

export const tagsOptions: Options = [
    { value: 'container-broken', label: 'Contenant SAV' },
    { value: 'container-damaged', label: 'Contenant endommagé' },
    { value: 'not-found', label: 'Contenant introuvable' },
    { value: 'container-no-network', label: 'Contenant sans réseau' },
    { value: 'sensor-wrong-measure', label: 'Sensor à nettoyer' },
    { value: 'sensor-no-comm', label: 'Sensor ne communique pas' },
];

