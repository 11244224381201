import Icon from "../../../components/Icons/Icon";
import {ICONS} from "../../../components/Icons/icons";
import {Button} from "../../../lib/Buttons/Button";
import Drawer from "../../../components/Drawer/Drawer";
import {Container, SensorContextType} from "../../../components/Context/ContextType";
import {useSensorContext} from "../../../components/Context/Context";
import {isContainerReadOnlyOrganization} from "../../../domain/organization-rule";

type ContainerDrawerProps = {
    container: Container,
    fromWizard: boolean,
    onEdit: () => void
    onConfirm: () => void
}
export const ContainerDrawer = ({container, fromWizard, onEdit, onConfirm}: ContainerDrawerProps) => {
    const sensorContext: SensorContextType = useSensorContext();
    const canEditContainer = !isContainerReadOnlyOrganization(sensorContext.getUserOrganization());

    return (
        <Drawer>
            <>
                <div>
                    <img
                        src={
                            container?.picture
                                ? container.picture + '?width=72'
                                : '/images/placeholder_container.svg'
                        }
                        alt=""
                    />
                    <div className="drawer__container-details">
                        <h3> {container.name}</h3>
                        {canEditContainer && (<button
                            type="button"
                            className="edit"
                            onClick={onEdit}
                        >
                            <Icon iconPath={ICONS.EDIT}/>
                        </button>)}
                        {
                            !canEditContainer &&
                            <p>Si vous souhaitez modifier ce contenant, veuillez vous rendre sur votre application
                                iSmart.</p>
                        }
                        <p className="container-list__sensors">
                            {container.activeContracts.map((contract: any) => {
                                return (
                                    <span
                                        key={contract.sensor.serialNumber}
                                        className="container-list__sensors-container"
                                    >
												<svg
                                                    className="container-list__sensors-container_icon-sensor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
													<path
                                                        fillRule="evenodd"
                                                        d="M17.75,0 C18.9922136,0 20,1.00778644 20,2.25 L20,4.601 L22.25,4.601 C22.6296958,4.601 22.943491,4.88315388 22.9931534,5.24922944 L23,5.351 C23,5.76521356 22.6642136,6.101 22.25,6.101 L22.25,6.101 L20,6.101 L20,9.101 L22.25,9.101 C22.6296958,9.101 22.943491,9.38315388 22.9931534,9.74922944 L23,9.851 C23,10.2652136 22.6642136,10.601 22.25,10.601 L22.25,10.601 L20,10.601 L20,13.601 L22.25,13.601 C22.6296958,13.601 22.943491,13.8831539 22.9931534,14.2492294 L23,14.351 C23,14.7652136 22.6642136,15.101 22.25,15.101 L22.25,15.101 L20,15.101 L20,18.101 L22.25,18.101 C22.6296958,18.101 22.943491,18.3831539 22.9931534,18.7492294 L23,18.851 C23,19.2652136 22.6642136,19.601 22.25,19.601 L22.25,19.601 L20,19.601 L20,21.75 C20,22.9922136 18.9922136,24 17.75,24 L7.25,24 C6.00778644,24 5,22.9922136 5,21.75 L5,19.601 L2.75,19.601 C2.37030423,19.601 2.05650904,19.3188461 2.00684662,18.9527706 L2,18.851 C2,18.4367864 2.33578644,18.101 2.75,18.101 L2.75,18.101 L5,18.101 L5,15.101 L2.75,15.101 C2.37030423,15.101 2.05650904,14.8188461 2.00684662,14.4527706 L2,14.351 C2,13.9367864 2.33578644,13.601 2.75,13.601 L2.75,13.601 L5,13.601 L5,10.601 L2.75,10.601 C2.37030423,10.601 2.05650904,10.3188461 2.00684662,9.95277056 L2,9.851 C2,9.43678644 2.33578644,9.101 2.75,9.101 L2.75,9.101 L5,9.101 L5,6.101 L2.75,6.101 C2.37030423,6.101 2.05650904,5.81884612 2.00684662,5.45277056 L2,5.351 C2,4.93678644 2.33578644,4.601 2.75,4.601 L2.75,4.601 L5,4.601 L5,2.25 C5,1.00778644 6.00778644,0 7.25,0 L17.75,0 Z M17.75,1.5 L7.25,1.5 C6.83621356,1.5 6.5,1.83621356 6.5,2.25 L6.5,21.75 C6.5,22.1637864 6.83621356,22.5 7.25,22.5 L17.75,22.5 C18.1637864,22.5 18.5,22.1637864 18.5,21.75 L18.5,2.25 C18.5,1.83621356 18.1637864,1.5 17.75,1.5 Z M16.25,19.5 C16.6642136,19.5 17,19.8357864 17,20.25 C17,20.6642136 16.6642136,21 16.25,21 L16.25,21 L14.75,21 C14.3357864,21 14,20.6642136 14,20.25 C14,19.8357864 14.3357864,19.5 14.75,19.5 L14.75,19.5 Z"
                                                    />
												</svg>
												<span className="container-list__sensor-container_serial-number">
													{contract.sensor.serialNumber}
												</span>
											</span>
                                );
                            })}
                        </p>
                    </div>
                </div>
                {fromWizard && (
                    <Button
                        type="button"
                        label="Confirmer"
                        onClick={onConfirm}
                    />
                )}
            </>
        </Drawer>
    )
}
