import React from 'react';
import {classWithModifiers} from "../../utils/css";

export const Radio = (props) => {
	const { label, value, classModifiers, message, name, checked } = props;
	return (
		// Radio
		<label
			className={classWithModifiers('hl-radio', [...(classModifiers || [])])}
		>
			<input
				name={name}
				className="hl-radio__input"
				type="radio"
				value={value}
				defaultChecked={checked}
			/>
			<div className="hl-radio__label">{label}</div>
			{message && <div className="hl-radio__message">{message}</div>}
		</label>
		// ---
	);
};
