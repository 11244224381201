import React, { useContext, useLayoutEffect, useState } from 'react';
//styles
import './ContainerSummary.scss';
//navigation
import { useHistory } from 'react-router';
//context
import CreateContainerContext from '../../../components/Context/createContainerContext/Context';
import SensorContext from '../../../components/Context/Context';
import { CreateContainerContextType } from '../../../components/Context/createContainerContext/ContextType';
import {
	Container,
	SensorContextType
} from '../../../components/Context/ContextType';
import { createContainer } from '../../../api';
import { getCurrentPosition } from '../../../api/geolocation';
//lib
import { HeaderTitleWithBackButton } from '../../../components/Header/Header';
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from "../../../components/Context/OriginCreateContainerContext";


export default function ContainerSummary() {
	const { fromWizard } = useContext(
		OriginCreateContainerContext
	) as OriginCreateContainerContextType;

	const history = useHistory();
	const createContainerContext: CreateContainerContextType = useContext(
		CreateContainerContext
	) as CreateContainerContextType;
	const sensorContext: SensorContextType = useContext(SensorContext);
	const [saving, setSaving] = useState(false);

	const save = async () => {
		const coords = await getCurrentPosition();
		const override = {
			longitude: coords.coords.longitude,
			latitude: coords.coords.latitude
		};
		createContainerContext.overrideContainer(override);

		const containerToCreate = {
			...createContainerContext.container,
			...override
		};

		const container: Container = await createContainer(
			createContainerContext.container.group.id,
			containerToCreate
		).then((response) => response.json());

		if (fromWizard) {
			sensorContext.setSensor({
				...sensorContext.sensor,
				container: {
					...container,
					properties: {
						CONTAINER_EMPTY_MEASURE:
							createContainerContext.container.properties
								?.CONTAINER_EMPTY_MEASURE,
						CONTAINER_FULL_MEASURE:
							createContainerContext.container.properties
								?.CONTAINER_FULL_MEASURE
					}
				}
			});

			history.push('/wizard/container-validation?from=container-creation');
		} else {
			history.push('/containers-list?from=container-creation');
		}
	};

	// We disabled this ESlint rule, because we want to be sure that the callback is executed only one time.
	/* eslint-disable react-hooks/exhaustive-deps */
	useLayoutEffect(() => {
		if (saving) {
			save();
		}
	}, [saving]);

	const goNext = async () => {
		setSaving(true);
	};

	return (
		<div className="createContainerSummary">
			<div className="createContainerSummary__title">
				<HeaderTitleWithBackButton title="Récapitulatif" />
			</div>
			<div className="recap">
				<div className="recap__container">
					<dl className="recap__container-section">
						<dt className="recap__container-label">Nom</dt>
						<dd id="createContainerTitle" className="recap__container-value">
							{createContainerContext.container.name}
						</dd>
					</dl>
					<dl className="recap__container-section">
						<dt className="recap__container-label">Groupe</dt>
						<dd id="createContainerGroup" className="recap__container-value">
							{createContainerContext.container.group.name}
						</dd>
					</dl>
					<dl className="recap__container-section">
						<dt className="recap__container-label">Modèle </dt>
						<dd id="createContainerModel" className="recap__container-value">
							{createContainerContext.container.model.name}
						</dd>
					</dl>
					<dl className="recap__container-section">
						<dt className="recap__container-label">Flux </dt>
						<dd id="createContainerFlow" className="recap__container-value">
							{createContainerContext.container.flowLabel}
						</dd>
					</dl>
					{
						createContainerContext.container.depositLabel && <dl className="recap__container-section">
							<dt className="recap__container-label">PAV</dt>
							<dd id="createContainerFlow" className="recap__container-value">
								{createContainerContext.container.depositLabel}
							</dd>
						</dl>
					}
				</div>
				<button
					disabled={saving}
					className="recap__next-button btn"
					type="button"
					onClick={goNext}
				>
					Valider
				</button>
			</div>
		</div>
	);
}
