import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
//helper
import { PrivateRoute } from '../../lib/Helper/RoutesHelper';
//pages
import ContainerName from '../wizard/newContainerWizard/ContainerName';
import ListGroup from './newContainerWizard/ListGroup';
import ListContainerModel from './newContainerWizard/ListContainerModel';
import ContainerSummary from './newContainerWizard/ContainerSummary';
import ListFlow from './newContainerWizard/ListFlow';
import ListDepositPoint from './newContainerWizard/ListDepositPoint';

export default function CreateContainer() {
	let { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<Redirect to={`${path}/naming`} />
			</Route>
			<PrivateRoute path={`${path}/naming`} Component={ContainerName} />
			<PrivateRoute path={`${path}/groups`} Component={ListGroup} />
			<PrivateRoute
				path={`${path}/container-models`}
				Component={ListContainerModel}
			/>
			<PrivateRoute path={`${path}/flows`} Component={ListFlow} />
			<PrivateRoute path={`${path}/deposit`} Component={ListDepositPoint} />
			<PrivateRoute path={`${path}/summary`} Component={ContainerSummary} />
		</Switch>
	);
}
