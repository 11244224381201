import {createContext, ReactElement, useContext, useState} from 'react';
import { CreateContainerContextType } from './ContextType';

export type CreateContainerContextGlobalType = CreateContainerContextType | {};


const CreateContainerContext = createContext<CreateContainerContextGlobalType>({});

export const useCreateContainerContext = () => {
	const context = useContext(CreateContainerContext);

	if(context === {}){
		throw new Error("Le context CreateContainerContext n'existe pas. Assurez-vous d'avoir utiliser le composant CreateContainerContextProvider");
	}
	return context as CreateContainerContextType;
}

const defaultContainer: CreateContainerContextGlobalType | {} = {};

export function CreateContainerProvider({
	children
}: {
	children: ReactElement;
}) {
	const [container, setContainer] = useState(defaultContainer);

	const overrideContainer = (override: Object) => {
		setContainer({
			...container,
			...override
		});
	};

	return (
		<CreateContainerContext.Provider
			value={{
				container,
				setContainer,
				overrideContainer
			}}
		>
			{children}
		</CreateContainerContext.Provider>
	);
}

export default CreateContainerContext;
