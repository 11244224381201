import React, {ChangeEvent, SyntheticEvent, useContext, useEffect, useState} from 'react';
//styles
import './ListGroup.scss';
//navigation
import { useHistory } from 'react-router';
//context
import {
	Container,
} from '../../../components/Context/createContainerContext/ContextType';
import CreateContainerContext, {
	CreateContainerContextGlobalType
} from '../../../components/Context/createContainerContext/Context';
//api
import { getContainerModelList } from '../../../api';
//components
import { HeaderTitleWithBackButton } from '../../../components/Header/Header';
import Loading from '../../../components/FabButton/Loading/Loading';
import { InputText } from '../../../lib/Forms/InputText/InputText';
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from "../../../components/Context/OriginCreateContainerContext";
import {FOUND, NOTFOUND} from "../../../api/model";



export default function ListContainerModel() {
	const { organizationId, urlPrefix } = useContext(OriginCreateContainerContext) as OriginCreateContainerContextType;

	const history = useHistory();
	const createContainerContext: CreateContainerContextGlobalType = useContext(
		CreateContainerContext
	) as CreateContainerContextGlobalType;
	const [nextUrl, setNextUrl] = useState<string>(
		`/v2/organizations/${organizationId}/container_models?with=brand&order=[default]=desc&order[brand.name]=asc&order[name]=asc&itemsPerPage=100`
	);
	const [status, setStatus] = useState(NOTFOUND);
	const [filter, setFilter] = useState<string | undefined>();
	const [containerModelList, setContainerModelList] = useState<any[]>([]);
	const [modelIri, setModelIri] = useState<Container | null>(null);

	const goNext = () => {
		history.push(urlPrefix + 'create/flows');
	};

	const getContainerModelListData = (nextUrl: string) => {
		getContainerModelList(
			organizationId,
			nextUrl
		).then(({ containerModelList: data, next }) => {
			setStatus(FOUND);
			nextUrl
				? setContainerModelList([...containerModelList, ...data])
				: setContainerModelList(data);
			setNextUrl(next);
		});
	};

	useEffect(
		() => {
			if (nextUrl) {
				getContainerModelListData(nextUrl);
			}
		}, // eslint-disable-next-line
		[nextUrl]
	);

	const onKeyDown = (e: SyntheticEvent<HTMLInputElement>) => {
		const input: HTMLInputElement = e.target as HTMLInputElement;
		const value = input.value;
		setFilter(value.toLowerCase());
	};

	const filtered = containerModelList.filter((model) => {
		return (
			!filter ||
			model['brand']?.name.toLowerCase().indexOf(filter) >= 0 ||
			model.name.toLowerCase().indexOf(filter) >= 0
		);
	});
	return (
		<div className="createContainerPage">
			<HeaderTitleWithBackButton title="Sélectionner un modèle" />
			<div
				id="ContainerSelector"
				className={modelIri ? 'disabled' : ''}
				onClick={() => modelIri && setModelIri(null)}
			>
				<div className="container-list-group">
					<div className="container-model-filter">
						<InputText
							placeholder="Nom de la marque ou nom du modèle"
							type="text"
							onKeyUp={(e: ChangeEvent<HTMLInputElement>) => onKeyDown(e)}
							classModifiers={[]}
						/>
					</div>

					{status === NOTFOUND && <Loading />}
					{status === FOUND && (
						<ul id="containerList">
							{filtered.map((model) => {
								const prefix = model['brand']
									? model['brand'].name + ' - '
									: '';
								return (
									<li
										key={model['@id']}
										role="button"
										tabIndex={0}
										onClick={() => {
											setModelIri(model.id);
											createContainerContext.overrideContainer({
												model: {
													name: prefix + model.name,
													id: model['@id']
												},
												properties: model.properties
											});
											goNext();
										}}
									>
										<p className="cardTitle">{prefix + model.name}</p>
										<p className="cardTitle">{model.type}</p>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
}
