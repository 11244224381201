import Modal from '../../../components/Modal/Modal';
import React from 'react';
import { Container } from '../../../components/Context/ContextType';

type OnChoiceSelectPayload = {
	action: string;
	serialNumbers: string[];
	tag: 'sensor-lost' | 'sensor-ko';
};

type AskForStatusProps = {
	container: Container;
	onChoiceSelect: (payload: OnChoiceSelectPayload) => void;
};

export const AskForStatus = ({
	container,
	onChoiceSelect
}: AskForStatusProps) => {
	const serialNumbers = container?.activeContracts.map(
		(contract: any) => contract.sensor.serialNumber
	);
	return (
		<Modal>
			<h3>Attention</h3>
			<p>Quel est l’état du capteur?</p>
			<p className="flex-justify-around">
				<button
					type="button"
					className="btn btn--small"
					onClick={() => {
						onChoiceSelect({
							action: 'replace',
							serialNumbers,
							tag: 'sensor-lost'
						});
					}}
				>
					<span className="btn__label">Disparu</span>
				</button>
				{container.activeContracts && (
					<button
						type="button"
						className="btn btn--small"
						onClick={() => {
							onChoiceSelect({
								action: 'replace',
								serialNumbers,
								tag: 'sensor-ko'
							});
						}}
					>
						<span className="btn__label btn__label--white">
							Ne fonctionne pas
						</span>
					</button>
				)}
			</p>
		</Modal>
	);
};
