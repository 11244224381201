import Modal from "../../../../components/Modal/Modal";
import React, {FormEvent, useState} from "react";
import {InputText} from "../../../../lib/Forms/InputText/InputText";

type DepositPointModalProps = {
    onCancel: () => void;
    onValidate: (name: string) => void;
}
export const DepositPointModal = ({ onCancel, onValidate }: DepositPointModalProps) => {
    const [name, setName] = useState('');

    return (
        <Modal>
            <h3> Nouveau PAV</h3>
            <p>La géolocalisation du PAV sera celle du contenant en cours d’installation</p>
            <InputText
                value={name}
                onChange={(e: FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                placeholder="Nom du PAV"
                type="text"
                classModifiers={['full-width']}
            />
            <p className="flex-justify-around">
                <button
                    type="button"
                    className="btn btn--small btn--secondary"
                    onClick={onCancel}
                >
                    <span className="btn__label">Annuler</span>
                </button>
                <button type="button" className="btn btn--small" onClick={() => onValidate(name)}>
							<span
                                className="btn__label btn__label--white"
                            >
								Créer
							</span>
                </button>
            </p>
        </Modal>
    )
}
