import React, { useContext, useEffect, useState } from 'react';
//styles
import './ListGroup.scss';
import { HeaderTitleWithBackButton } from '../../../components/Header/Header';
//navigation
import { useHistory } from 'react-router';
//context
import { createPav, fetchAllDepositPoints } from '../../../api/depositPoints';
//libs
import { useCreateContainerContext } from '../../../components/Context/createContainerContext/Context';
import { DepositPoint } from '../../../components/Context/ContextType';
import {
	OriginCreateContainerContext,
	OriginCreateContainerContextType
} from '../../../components/Context/OriginCreateContainerContext';
import { Filter } from './ListDepositPointComponents/Filter';
import { DepositPointModal } from './ListDepositPointComponents/DepositPointModal';
import { CreateContainerContextType } from '../../../components/Context/createContainerContext/ContextType';
import { DepositPointList } from './ListDepositPointComponents/DepositPointList';

export default function ListDepositPoint() {
	const history = useHistory();
	const { organizationId, urlPrefix } = useContext(
		OriginCreateContainerContext
	) as OriginCreateContainerContextType;
	const [selectedValue, setSelectValue] = useState<DepositPoint | null>(null);
	const [depositPoints, setDepositPoints] = useState<DepositPoint[]>([]);
	const [filter, setFilter] = useState<string | undefined>();
	const [pavCreation, setPavCreation] = useState<boolean>(false);
	const createContainerContext: CreateContainerContextType =
		useCreateContainerContext();

	const goNext = () => {
		history.push(urlPrefix + 'create/summary');
	};

	useEffect(() => {
		fetchAllDepositPoints(organizationId).then((depositPoints) =>
			setDepositPoints(depositPoints)
		);
	}, [organizationId]);

	const filtered = depositPoints.filter(
		(o) => !filter || o?.name.toLowerCase().indexOf(filter) >= 0
	);

	const createPavHandler = async (name: string) => {
		const depositPoint = await createPav(name, organizationId).then(
			(response) => response.json()
		);
		setDepositPoints([...depositPoints, depositPoint]);
		selectDepositPoint(depositPoint);
	};

	const selectDepositPoint = (d: DepositPoint) => {
		createContainerContext.overrideContainer({
			depositPoint: d['@id'],
			depositLabel: d.name
		});
		goNext();
	};

	return (
		<div className="createContainerPage">
			<p className="flex-justify-around">
				<button
					type="button"
					className="btn btn--small btn--secondary"
					onClick={goNext}
				>
					<span className="btn__label">Ne pas associer de PAV</span>
				</button>
				<button
					type="button"
					className="btn"
					onClick={() => setPavCreation(true)}
				>
					<span className="btn__label">Créer un PAV</span>
				</button>
			</p>
			{pavCreation && (
				<DepositPointModal
					onCancel={() => setPavCreation(false)}
					onValidate={createPavHandler}
				></DepositPointModal>
			)}
			<HeaderTitleWithBackButton title="Choisir un PAV" />
			<div
				id="ContainerSelector"
				className={selectedValue ? 'disabled' : ''}
				onClick={() => selectedValue && setSelectValue(null)}
			>
				<div className="container-list-group">
					<Filter onKeyUp={setFilter} />
					<DepositPointList
						list={filtered}
						onSelect={selectDepositPoint}
					></DepositPointList>
				</div>
			</div>
		</div>
	);
}
