import { Container } from '../../../components/Context/ContextType';
import Modal from '../../../components/Modal/Modal';
import React from 'react';

type AskForSavProps = {
	container: Container;
	onYes: () => void;
	onNo: () => void;
};

export const AskForSav = ({ container, onYes, onNo }: AskForSavProps) => {
	const serialNumbers = container?.activeContracts
		.map((contract: any) => contract.sensor.serialNumber)
		.join(', ');
	return (
		<Modal>
			<h3>Attention</h3>
			<p>
				Voulez-vous notifier le SAV que les capteurs{' '}
				<strong>{serialNumbers}</strong> ont été remplacés ?
			</p>
			<p className="flex-justify-around">
				<button
					type="button"
					className="btn btn--small btn--secondary"
					onClick={onNo}
				>
					<span className="btn__label">Non</span>
				</button>
				{container.activeContracts && (
					<button type="button" className="btn btn--small" onClick={onYes}>
						<span className="btn__label btn__label--white">Oui</span>
					</button>
				)}
			</p>
		</Modal>
	);
};
