import ReactSelect from 'react-select';
import React, { ComponentProps } from 'react';
import {Option, Options, statusOptions, tagsOptions} from "../../api/model";


type StatusSelectProps = {
	onChange: (value: string) => void;
	value: string;
};
export const StatusSelect = (
	props: ComponentProps<typeof ReactSelect> & StatusSelectProps
) => {

	const options = [ { value: '', label: 'Tout afficher'}, ...statusOptions]
	return (
		<Select
			{...props}
			options={options}
			placeholder={<>Statut</>}
			onChange={(v: Option) => props.onChange(v.value)}
			value={statusOptions.find((o) => o.value === props.value)}
		/>
	);
};

type TagSelectProps = {
	onChange: (value: string[]) => void;
};
export const TagsSelect = (
	props: ComponentProps<typeof ReactSelect> & TagSelectProps
) => {
	return (
		<Select
			{...props}
			options={tagsOptions}
			isMulti={true}
			placeholder={<>Tags</>}
			onChange={(values: Options) => props.onChange(values.map((v: Option) => v.value))}
		/>
	);
};

export const Select = (props: ComponentProps<typeof ReactSelect>) => {
	return (
		<ReactSelect
			styles={{
				placeholder: (baseStyles) => ({
					...baseStyles,
					fontSize: '1.4rem',
					fontWeight: '500'
				}),
				control: (baseStyles) => ({
					...baseStyles,
					border: 'none',
					background: '#f4f7f9',
					marginTop: '1em'
				})
			}}
			{...props}
		/>
	);
};
